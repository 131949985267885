import classNames from 'classnames';
import AlertModal from 'components/AlertModal';
import BaseModal from 'components/BaseModal';
import { Button } from 'components/Buttons';
import Modal from 'components/Modal';
import useOrdersHook from 'hooks/useOrdersHook';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import styles from './DonationSettings.module.scss';

interface DonationSettingsProps {
  open: boolean;
  onClose: () => void;

}
function DonationSettings({open, onClose}: DonationSettingsProps): ReactElement {

  const [newPlasticsPrice, setNewPlasticsPrice] = useState<number>()
  const [newTreePrice, setNewTreePrice] = useState<number>()
  const [openAlertModal, setOpenAlertModal] = useState<boolean>(false)

  const {
    getDonationSettings,
    updateDonationSettings,
    plasticsPrice,
    treesPrice,
  } = useOrdersHook();

  const onSave = async () => {
    try {
      if (newPlasticsPrice === undefined || newTreePrice === undefined) return;
      await updateDonationSettings({plasticsPrice: newPlasticsPrice, treesPrice: newTreePrice})
      setOpenAlertModal(true)
     
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!open) return;
    getDonationSettings();
  }, [open])

  useEffect(() => {
    setNewPlasticsPrice(plasticsPrice)
    setNewTreePrice(treesPrice)
  }, [treesPrice, plasticsPrice])

  return (
    <>
      {open && (
        <BaseModal>
          <div className={styles.modal}>
            <div className={styles.title}>{'Donation Settings'}</div>
            <div className={styles.message}>
              <label className={styles.label}>{'Plastics Price ($/g):'}</label>
              <input type={'number'} value={newPlasticsPrice} step={0.001} onChange={({target}) => setNewPlasticsPrice(target.value ? +target.value : undefined)}/>
            </div>
            <div className={styles.message}>
              <label className={styles.label}>{'Trees Price ($/tree):'}</label>
              <input type={'number'} value={newTreePrice} step={0.001} onChange={({target}) => setNewTreePrice(target.value ? +target.value : undefined)}/>
            </div>
            
            <div className={styles.button}>
              <Button
                onClick={onClose}
                className={classNames(styles.orderButton, styles.baseButton)}
              >
                Close
              </Button>
              <Button
                onClick={onSave}
                className={classNames(styles.orderButton, styles.baseButton)}
              >
                Save
              </Button>
            </div>
          </div>
          <AlertModal 
            open={openAlertModal}
            title={'Donation Settings Changed'}
            message={
              <div className={styles.updateSummary}>
                <div>{`Plastics: ${newPlasticsPrice} /g`}</div>
                <div>{`Trees: ${newTreePrice} / tree`}</div>
              </div>
              }
            onClose={() => {
              setOpenAlertModal(false)
              onClose()
            }}
          />

        </BaseModal>
      )}
    </>
    
  )
}

export default DonationSettings;