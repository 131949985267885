import React, { FC, memo, ReactElement, useEffect, useState } from "react";

import ClientsHook from "hooks/useClientsHook";
import styles from "./styles.module.scss";
import OrderRow from "components/OrderRow";
import { Client, CreditMemo, OrderBatchFields, OrderInformation, ProductFields,} from "types";
import useOrdersHook from "hooks/useOrdersHook";
import AlertModal from "components/AlertModal";
import { BsCalendar } from "@react-icons/all-files/bs/BsCalendar";
import { formatISODateOnly, sqlDateOnlyFormat, todaysDate } from "lib/commonFunctions";
import classNames from "classnames";
import ClientSelect from "components/ClientSelect";
import useDebounceHook from "hooks/useDebounceHook";
import { Button } from "components/Buttons";
import TreeProducts from "pages/TreeProducts";
import DonationSettings from "components/DonationSettings";
import RowInfo from "./components/RowInfo";
import CreateCreditMemoModal from "./components/CreateCreditMemoModal";
import useCreditMemoHook from "hooks/useCreditMemoHook";
import CSVDownload from "components/CSVDownload";

import Toggle from "components/Toggle";
import { FOOD_SERVICE_SITE_ID, PANTREE_STIE_ID } from "lib/config";

const tableColumns = [
  "Date",
  "Order Number",
  "Notes",
  "Amount",
  "",
];

function CreditMemoForm(): ReactElement {

  const [selectedClient, setSelectedClient] = useState<Client>();
  const [searchFilter, setSearchFilter] = useState<string>();
  const [openCreateCreditMemo, setOpenCreateCreditMemo] = useState<boolean>(false);
  const [siteId, setSiteId] = useState<number>(PANTREE_STIE_ID)

  const debounceSearch = useDebounceHook(searchFilter);

  const { clients, fetchClients, clearClients } = ClientsHook();
  const { fetchOrdersAndCreditMemos, ordersAndMemos, deleteMemo, clearData } = useCreditMemoHook();

  const getTotal = () => {
   return ordersAndMemos.reduce((total, data) => {
      const price = +data.amount
      const amount: number = data.type == 'memo' ? -1 * price : price
      return amount + total
    }, 0)
  }

  const getCsvValues = (ordersAndMemos: Array<CreditMemo>) => {
    const csvValues = ordersAndMemos.map(data => {
      const isMemo = data.type == 'memo'
      return {
        date: data.date,
        type: data.type,
        notes: data.notes,
        orderNumber: isMemo ? '' : data.orderNumber,
        amount: data.amount,
      }
    })
    csvValues.push({date: '', type: '', orderNumber: '', notes:'Total Owing', amount: getTotal().toFixed(2)})
    return csvValues
  }

  const getOrdersAndMemos = () => {
    if (selectedClient) fetchOrdersAndCreditMemos(selectedClient.id, siteId)
  }

  const onCreateMemo = () => {
    getOrdersAndMemos();
  }

  const onDeleteMemo = async (memo: CreditMemo) => {
    await deleteMemo(memo.id)
    getOrdersAndMemos();
  }

  useEffect(() => {
    clearData()
    clearClients()
    setSearchFilter(' ')
  }, [siteId])

  useEffect(() => {
    clearData();
    getOrdersAndMemos();
  }, [selectedClient])

  useEffect(() => {
    fetchClients({limit: 50, searchFilter: debounceSearch, siteId});
  }, [debounceSearch])

  return (
    <div>
      <div className={styles.tableFilters}>
        <div className={styles.searchBar}>
          <div className={styles.searchIcons}></div>
          <ClientSelect
            updateSelectedClient={(client?: Client) => setSelectedClient(client)}
            clientList={clients}
            setValue={setSearchFilter}
            value={searchFilter}
            selectedClient={selectedClient}
          />
          <div className={styles.toggle}>
            <Toggle 
              buttonOneLabel={'Pantree'}
              buttonTwoLabel={'Food Service'}
              onButtonOneClick={() => setSiteId(PANTREE_STIE_ID)}
              onButtonTwoClick={() => setSiteId(FOOD_SERVICE_SITE_ID)}
            />
          </div>
     
          {ordersAndMemos.length > 0 && (
          <CSVDownload
            values={getCsvValues(ordersAndMemos)}
            headers={[
              {key: 'date', label: "Date"},
              {key: 'type', label: "Type"},
              {key: 'orderNumber', label: "Order Number"},
              {key: 'notes', label: "Notes"},
              {key: 'amount', label: "Amount"},
            ]}
            title={'Download CSV'}
            filename={`${sqlDateOnlyFormat(todaysDate)}_${selectedClient?.clientName.replaceAll(' ', '_')}`}
          />
        )}
        </div>
      </div>
      <div>
        <table className={styles.orderTable}>
          <thead>
            <tr key="HeaderRow">
              {tableColumns.map((col: string) => {
                return <th className={styles.header} key={col}>{col}</th>;
              })}
            </tr>
          </thead>
          <tbody>
              {ordersAndMemos.map(data => (
                <RowInfo 
                  key={data.id}
                  orderNumber={data.orderNumber}
                  amount={+data.amount}
                  notes={data.notes}
                  onDeleteMemo={() => onDeleteMemo(data)}
                  date={data.date}
                  creditMemo={data.type == 'memo'}
                />
              ))}
          </tbody>
        </table>
      </div>     
      {ordersAndMemos.length > 0 && (
        <div className={styles.total}>
          <div className={styles.title}>
            Total Owing:
          </div>
          <div className={getTotal() > 0 ? styles.owing : styles.credit}>
          <span>$</span>{`${getTotal().toFixed(2)}`}
          </div>
        </div>
      )}
      <div className={styles.buttons}>
        {selectedClient && (
          <Button 
            onClick={() => setOpenCreateCreditMemo(true)} 
            className={classNames(styles.orderButton,styles.baseButton)}>
              Create Memo
          </Button>
          
        )}
      </div>
      <CreateCreditMemoModal
        siteId={siteId}
        open={openCreateCreditMemo}
        onClose={() => setOpenCreateCreditMemo(false)}
        clientId={selectedClient?.id}
        onSave={() => {
          setOpenCreateCreditMemo(false);
          onCreateMemo();
        }}
      />
    </div>
  );
}

export default CreditMemoForm;
