import React, { ReactElement, ReactNode } from "react";
import styles from "./styles.module.scss";
import BaseModal from "components/BaseModal";
import { Button } from "components/Buttons";
import classNames from "classnames";

interface AlertModalProps {
  open: boolean;
  onClose: () => void;
  message?: string | ReactNode;
  title?: string;
}
function AlertModal({
  open,
  onClose,
  message,
  title,
}: AlertModalProps): ReactElement {
  return (
    <>
      {open && (
        <BaseModal>
          <div className={styles.modal}>
            <div className={styles.title}>{title}</div>
            <div className={styles.message}>{message}</div>
            <div className={styles.button}>
              <Button
                onClick={onClose}
                className={classNames(styles.orderButton, styles.baseButton)}
              >
                Close
              </Button>
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
}

export default AlertModal;
